import { theme } from "../../tailwind.config";

type Breakpoints = {
  readonly sm: string;
  readonly md: string;
  readonly lg: string;
  readonly xl: string;
};
export const breakpoints = Object.fromEntries(
  Object.entries(theme.screens).map(([name, width]) => {
    return [name, `@media (min-width: ${width})`];
  })
) as Breakpoints;
