import React from "react";
import classNames from "classnames";

export const Tags: React.FC<{
  readonly category: string;
  readonly tags: ReadonlyArray<string>;
}> = ({ category, tags }) => (
  <>
    <TagLink tag={category} path={`/categories/${category}`} primary />
    {tags.map((tag) => (
      <TagLink tag={`#${tag}`} path={`/tags/${tag}`} />
    ))}
  </>
);

const TagLink: React.FC<{
  readonly tag: string;
  readonly path: string;
  readonly primary?: boolean;
}> = ({ tag, primary = false }) => (
  <span
    className={classNames(
      primary
        ? "bg-blue-500 hover:bg-blue-400 text-gray-100"
        : "bg-gray-200 hover:bg-gray-300 text-gray-700",
      "inline-block text-xs leading-relaxed px-2 mr-1 rounded"
    )}
  >
    {tag}
  </span>
);
