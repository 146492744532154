import { css } from "@emotion/core";
import type { Toc } from "@suin/esa-toc";
import classNames from "classnames";
import { graphql, Link, PageProps } from "gatsby";
import React from "react";
import { DatoCmsPostQuery } from "../../../types/graphql-types";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import { breakpoints } from "../../css/breakpoints";
import { Tags } from "./tags";

const Column1: React.FC = ({ children }) => {
  return (
    <div className="hidden xl:block" style={{ width: 80 }}>
      {children}
    </div>
  );
};

const Column2: React.FC = ({ children }) => {
  return (
    <div className="flex-1 min-w-0 lg:px-6">
      <div className="mx-auto" style={{ maxWidth: 850 }}>
        {children}
      </div>
    </div>
  );
};

const Column3: React.FC<{ readonly show?: boolean }> = ({
  children,
  show = true,
}) => {
  if (show) {
    return (
      <div
        className="hidden lg:block"
        css={css`
          width: 200px;
          ${breakpoints.xl} {
            width: 300px;
          }
        `}
      >
        {children}
      </div>
    );
  } else {
    return null;
  }
};

type ArticleProps = {
  readonly title: string;
  readonly subtitle?: string;
  readonly content: string;
  readonly category: string;
  readonly tags: ReadonlyArray<string>;
  readonly date: string;
  readonly author: Author;
  readonly otherPost?: {
    readonly type: "author" | "category";
    readonly slug: string;
    readonly title: string;
  };
  readonly toc: Toc;
};

type Author = {
  readonly name: string;
  readonly images: Readonly<Record<"r32" | "r48", Image>>;
  readonly bio: string;
};

type Image = {
  readonly src: string;
  readonly srcSet: string;
};

const Article: React.FC<ArticleProps> = ({
  title,
  subtitle,
  content,
  category,
  tags,
  date,
  author,
  otherPost,
  toc,
}) => {
  const showRightAside = toc.length > 0;
  return (
    <article className="postArticle">
      {/* row1 */}
      <div className="flex flex-no-wrap">
        <Column1>{""}</Column1>
        <Column2>
          <header>
            <div>
              <h1 className="font-extrabold break-normal text-2xl sm:text-3xl md:text-4xl">
                {title}
              </h1>
              {subtitle && (
                <div className="mt-2 text-xl sm:text-2xl text-gray-700">
                  {subtitle}
                </div>
              )}
            </div>
            <div className="mt-3">
              <Tags category={category} tags={tags} />
            </div>
            <div className="mt-4 flex flex-row items-center text-gray-600">
              <span className="mx-1 font-medium">
                <img
                  className="mr-1 inline-block"
                  style={{ width: 32 }}
                  alt={author.name}
                  src={author.images.r32.src}
                  srcSet={author.images.r32.srcSet}
                />
              </span>
              <div>{author.name}</div>
              <div className="mx-1">・</div>
              <div className="text-sm">{date} 投稿</div>
            </div>
          </header>
        </Column2>
        <Column3 show={showRightAside}>{""}</Column3>
      </div>
      {/* row2 */}
      <div className="flex flex-no-wrap mt-4 pb-4">
        <Column1>
          <aside className="sticky top-0">{""}</aside>
        </Column1>
        <Column2>
          <section>
            <div
              className="markdown-body"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </section>
        </Column2>
        <Column3 show={showRightAside}>
          <aside className="sticky top-0 text-xs px-2 py-2 leading-loose">
            <div className="font-bold text-gray-600">もくじ</div>
            <div className="max-h-screen overflow-y-scroll">
              {toc.map(({ href, html, depth }) => {
                return (
                  <div
                    key={href}
                    className={classNames(
                      depth === 1 ? "pl-0" : depth === 2 ? "pl-2" : "pl-4"
                    )}
                  >
                    <a
                      className="text-gray-600 hover:text-gray-900"
                      href={href}
                      dangerouslySetInnerHTML={{ __html: html }}
                    />
                  </div>
                );
              })}
            </div>
          </aside>
        </Column3>
      </div>
      {/* row3 */}
      <div className="flex flex-no-wrap mt-4">
        <Column1>{""}</Column1>
        <Column2>
          <aside className="flex flex-col sm:flex-row">
            <div className="flex flex-1 mb-4 sm:mb-0 sm:mr-8">
              <img
                alt={author.name}
                src={author.images.r48.src}
                srcSet={author.images.r48.srcSet}
                style={{ width: 48 }}
                className="flex-grow-0 flex-shrink-0 self-start mr-2"
              />
              <div>
                <div className="text-sm font-semibold mb-1">{author.name}</div>
                <div className="text-xs">{author.bio}</div>
              </div>
            </div>
            <div className="flex-1">
              {otherPost ? (
                <>
                  <div className="text-xs font-semibold mb-2">
                    {otherPost.type === "author" ? author.name : category}
                    の他の投稿を読む
                  </div>
                  <Link
                    to={otherPost.slug}
                    className="block border-2 rounded-md p-3 text-gray-600 hover:text-white hover:bg-gray-500 border-gray-500 duration-75"
                  >
                    <div className="font-medium">{otherPost.title}</div>
                  </Link>
                </>
              ) : (
                ""
              )}
            </div>
          </aside>
        </Column2>
        <Column3 show={showRightAside}>{""}</Column3>
      </div>
    </article>
  );
};

declare namespace PostPage {
  type Context = {
    readonly id: string;
    readonly description: string;
    readonly toc: Toc;
    readonly ogpImageUrl: string;
    readonly otherPost?: {
      readonly type: "author" | "category";
      readonly slug: string;
      readonly title: string;
    };
  };
}

const PostPage: React.FC<PageProps<DatoCmsPostQuery, PostPage.Context>> = ({
  data,
  pageContext: { description, toc, ogpImageUrl, otherPost },
}) => {
  const post = data.post!;
  return (
    <Layout>
      <SEO title={post.title!} description={description} image={ogpImageUrl} />
      <Article
        title={post.title!}
        content={post.body!}
        category={post.category!}
        tags={post.tags ?? []}
        date={post.date}
        author={post.author as ArticleProps["author"]}
        otherPost={otherPost as ArticleProps["otherPost"]}
        toc={toc}
      />
    </Layout>
  );
};

// noinspection JSUnusedGlobalSymbols
export default PostPage;

// noinspection JSUnusedGlobalSymbols
export const pageQuery = graphql`
  query DatoCmsPost($id: String!) {
    post: datoCmsPost(id: { eq: $id }) {
      title
      author {
        name
        images: image {
          r32: fixed(
            imgixParams: { mask: "ellipse", fm: "png" }
            height: 32
            width: 32
          ) {
            src
            srcSet
          }
          r48: fixed(
            imgixParams: { mask: "ellipse", fm: "png" }
            height: 48
            width: 48
          ) {
            src
            srcSet
          }
        }
        bio
      }
      date(formatString: "LL", locale: "ja")
      tags
      category
      body
    }
  }
`;
